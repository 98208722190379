.Steps {
  display: grid;
  gap: clamp(48px, calc(64vw / 7.68), 64px) 32px;
  align-items: center;
  @media (min-width: 1000px) {
    grid-template-columns: 1.5fr auto 2.5fr;
  }
  .timeline {
    margin: 0 48px;
    display: grid;
    gap: 32px;
    counter-reset: counter;
    .item {
      counter-increment: counter;
      display: grid;
      grid-template-columns: 182px 1fr;
      align-items: center;
      gap: 32px;
      .name {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 12px;
        padding: 4px 16px 4px 4px;
        border-radius: 48px;
        background-color: var(--neutral-200);
        transition: background-color 0.3s var(--easing), color 0.3s var(--easing);
        color: var(--neutral-700);
        span {
          font-size: 1rem;
        }
        &::before {
          content: counter(counter);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--neutral-300);
          display: grid;
          place-items: center;
          transition: background-color 0.3s var(--easing), color 0.3s var(--easing);
          color: var(--neutral-600);
        }
      }
      &:nth-child(-n + 9) {
        .name {
          &::before {
            content: '0' counter(counter);
          }
        }
      }
      &:has(.when[data-active='true']) {
        .name {
          background-color: var(--neutral-800);
          color: var(--neutral-200);
          &::before {
            background-color: var(--neutral-700);
            color: var(--neutral-300);
          }
        }
      }
      .when {
        position: relative;
        &[data-active='true'] {
          &::after {
            transform: translateY(-50%) scale(1);
          }
        }
        &::after {
          content: '';
          position: absolute;
          left: -21.5px;
          top: 50%;
          width: 12px;
          height: 12px;
          border: 1px solid var(--neutral-800);
          background-color: var(--neutral-800);
          border-radius: 50%;
          transform: translateY(-50%) scale(0);
          transition: transform 0.3s var(--easing);
        }
        &::before {
          content: '';
          position: absolute;
          left: -21.5px;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          border: 1px solid var(--neutral-300);
          border-radius: 50%;
          background-color: var(--neutral-100);
        }
      }
    }
    position: relative;
    .progressbar {
      position: absolute;
      top: 24px;
      left: 198px;
      width: 1px;
      height: calc(100% - 48px);
      background-color: var(--neutral-300);
      div {
        width: 100%;
        height: 100%;
        background-color: var(--neutral-800);
        transform-origin: top;
      }
    }
  }
  .ctaBox {
    border-radius: 16px;
    background: var(--neutral-300, #f0f2f7);
    padding: 32px;
    overflow: hidden;
    text-align: center;
    h3 {
      font-size: clamp(calc(20rem / 16), calc(24vw / 7.68), calc(24rem / 16));
      color: var(--neutral-700);
    }
    .paragraph {
      margin: 12px 0 32px;
    }
    a {
      margin: 0 auto;
    }
    .img {
      margin: 64px -32px -32px 0;
      overflow: hidden;
      border-top-left-radius: 16px;
      box-shadow: 0px 11px 11px 0px rgba(127, 168, 210, 0.09), 0px 25px 15px 0px rgba(127, 168, 210, 0.05);
      img {
        transform-origin: left top;
        max-width: 105%;
        transition: transform 0.5s var(--easing);
        display: block;
      }
    }
    &:has(a:hover) {
      .img {
        img {
          transform: translateX(-2.5%);
        }
      }
    }
  }
  @media (max-width: 999px) {
    header {
      text-align: center;
    }
    header,
    .timeline {
      max-width: calc(380rem / 16);
      margin: 0 auto;
    }
    .timeline {
      justify-content: center;
    }
    .ctaBox {
      max-width: calc(480rem / 16);
      margin: 0 auto;
    }
  }
}
