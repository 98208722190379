.Button {
  min-width: 48px;
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;
  padding: 4px 4px 4px 20px;
  border-radius: 40px;
  background: var(--primary-400);
  color: var(--neutral-200);
  font-size: 1rem;
  overflow: hidden;
  span {
    z-index: 3;
    position: relative;
  }
  .icon {
    z-index: 3;
    background: var(--primary-300);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    svg {
      flex-shrink: 0;
      &:last-child {
        margin-right: -10px;
      }
      width: 100%;
      transition: transform 0.5s var(--easing);
    }
    overflow: hidden;
  }
  position: relative;
  &::before {
    content: '';
    width: 200%;
    aspect-ratio: 1/1;
    background: var(--primary-300);
    transition: transform 0.5s var(--easing);
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    right: 24px;
    top: 50%;
    transform: translate(50%, -50%) scale(0);
  }
  &:active {
    &::before {
      transform: translate(50%, -50%) scale(1);
    }
  }
  &:hover,
  &:focus-visible {
    .icon {
      svg {
        transform: translateX(100%);
      }
    }
  }
  &:disabled {
    opacity: 0.62;
    pointer-events: none;
  }
}
