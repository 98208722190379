.Team {
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: clamp(8px, calc(24vw / 7.68), 24px);
    margin-bottom: clamp(24px, calc(48vw / 7.68), 48px);
    list-style-type: none;
    .item {
      @media (min-width: 900px) {
        width: calc(50% - clamp(8px, calc(24vw / 7.68), 24px) * 1 / 2);
      }
      @media (min-width: 1100px) {
        width: calc((100% / 3) - clamp(8px, calc(24vw / 7.68), 24px) * 2 / 3);
      }
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 12px;
      img {
        width: clamp(120px, calc(148vw / 7.68), 148px);
        object-fit: cover;
        border-radius: 16px;
      }
      > div {
        padding: 8px;
        h3 {
          font-size: 1rem;
          margin-bottom: 8px;
          color: var(--neutral-700, #333436);
        }
        .paragraph {
          font-size: calc(14rem / 16);
        }
      }
    }
  }
  .column {
    display: grid;
    gap: clamp(24px, calc(32vw / 7.68), 32px);
    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 899px) {
      max-width: calc(450rem / 16);
    }
    h2,
    h3 {
      color: var(--neutral-700);
    }
    h2 {
      font-size: clamp(calc(20rem / 16), calc(24vw / 7.68), calc(24rem / 16));
    }
    h3 {
      font-size: 1rem;
      margin-bottom: 16px;
    }
    .paragraph {
      > *:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}
