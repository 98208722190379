.Picker {
  > p {
    margin: 0 6px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px 4px;
    flex-wrap: wrap;
    svg {
      flex-shrink: 0;
    }
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    label {
      display: grid;
      grid-template-columns: auto auto;
      gap: 2px;
      place-items: center;
      cursor: pointer;
      height: 44px;
      padding: 8px 12px;
      font-size: 1rem;
      border-radius: 4px;
      border: 1px solid #c2d3d3;
      background-color: var(--neutral-200);
      transition: border-color 0.5s var(--easing), opacity 0.5s var(--easing), background-color 0.5s var(--easing);
      input {
        opacity: 0;
        position: absolute;
      }
      .indicator {
        width: 0;
        transition: width 0.5s var(--easing);
      }
      &:has(input:focus-visible) {
        border-color: var(--secondary-500);
      }
      &:hover {
        background-color: var(--neutral-300);
      }
      &:has(input:checked) {
        border-color: var(--secondary-500);
        .indicator {
          width: 12px;
        }
      }
    }
    .input {
      @media (min-width: 500px) {
        max-width: 134px;
      }
      width: 100px;
      flex-grow: 1;
      height: 44px;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid #c2d3d3;
      background-color: var(--neutral-200);
      transition: border-color 0.5s var(--easing);
      font-size: 1rem;
      transition: border-color 0.5s var(--easing);
      &:focus-visible {
        outline: none;
        border-color: var(--secondary-500);
      }
    }
    &:has(.input:not(:placeholder-shown)) {
      label {
        opacity: 0.62;
      }
    }
  }
  &[aria-invalid='true'] {
    .options {
      label,
      .input {
        border-color: var(--error-500);
      }
    }
  }
}
