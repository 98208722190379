.Faq {
  max-width: calc(597em / 16);
  margin: 0 auto;
  header {
    text-align: center;
    margin-bottom: clamp(32px, calc(48vw / 7.68), 48px);
  }
}

.List {
  display: grid;
  gap: clamp(12px, calc(12vw / 7.68), 16px);
  details {
    border-radius: 12px;
    background: var(--neutral-200);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      box-shadow: 0px 11px 11px 0px rgba(127, 168, 210, 0.09), 0px 25px 15px 0px rgba(127, 168, 210, 0.05);
      transition: opacity 0.5s var(--easing);
      z-index: -1;
    }
    summary {
      padding: 12px 16px;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 12px;
      font-size: 1rem;
      line-height: 160%;
      color: var(--neutral-700);
      position: relative;
      &::before {
        content: '';
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: opacity 0.5s var(--easing);
        background-color: var(--neutral-300);
        opacity: 0;
      }
      &::after {
        content: '';
        border-bottom: 1px dashed var(--neutral-300);
        position: absolute;
        left: 16px;
        right: 16px;
        position: absolute;
        bottom: 0;
        transition: opacity 0.5s var(--easing);
      }
      .indicator {
        z-index: 2;
        width: 16px;
        height: 16px;
        background-color: rgba(55, 101, 120, 0.5);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        span {
          position: absolute;
          display: block;
          width: 5px;
          height: 1px;
          border-radius: 5px;
          background-color: var(--secondary-200);
          &:first-child {
            transition: transform 0.5s var(--easing);
          }
        }
      }
      span {
        z-index: 2;
      }
    }
    .answer {
      overflow: hidden;
      padding: 0 16px;
      > *:not(:first-child) {
        margin-top: 12px;
      }
      > * + ul,
      > * + ol {
        margin-top: 8px !important;
      }
    }
    &[data-opened='false'] {
      &::before {
        opacity: 0;
      }
      summary {
        &:hover,
        &:focus-visible {
          &::before {
            opacity: 1;
          }
        }
        &::after {
          opacity: 0;
        }
      }
      .indicator {
        span {
          &:first-child {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
