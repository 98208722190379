*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --neutral-100: #ffffff;
  --neutral-200: #f9fafc;
  --neutral-300: #f0f2f7;
  --neutral-400: #dfe1e5;
  --neutral-500: #969799;
  --neutral-600: #5e5f60;
  --neutral-700: #333436;
  --neutral-800: #171719;

  --primary-300: #539e73;
  --primary-400: #377853;

  --secondary-200: #376578;
  --secondary-300: #5f9cb6;
  --secondary-500: #376578;

  --error-500: #e3204e;

  --form-100: var(--neutral-500);
  --form-200: var(--neutral-700);
  --form-300: var(--secondary-500);

  --pageMargin: clamp(16px, calc(40vw / 7.68), 40px);
  @media (max-width: 899px) and (min-width: 649px) {
    --pageMargin: clamp(40px, calc(80vw / 7.68), 80px);
  }
  --easing: cubic-bezier(0.65, 0.05, 0.36, 1);
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 152px;
}
html,
body {
  overflow-x: clip;
}
body {
  overflow: clip;
  -webkit-tap-highlight-color: transparent;
  min-width: 320px;
  font-family: 'Lato', serif;
  background-color: var(--neutral-100);
  color: var(--neutral-600);
  font-size: calc(14rem / 16);
  line-height: 168%;
}
:focus {
  outline: none;
}
:focus-visible {
  outline: 1px solid var(--neutral-700);
  outline-offset: 5px;
}
main,
.max-width {
  max-width: 1200px;
  width: calc(100% - var(--pageMargin) * 2);
  margin: 0 auto;
  height: 100%;
}
svg {
  display: block;
  max-width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
  vertical-align: bottom;
}
label {
  display: block;
}
button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
sup {
  font-size: 0.6em;
  vertical-align: top;
}
input,
textarea,
button,
select {
  font: inherit;
  color: inherit;
  background-color: transparent;
  appearance: none;
  border: none;
}
summary {
  cursor: pointer;
  list-style: none;
  &::marker,
  &::-webkit-details-marker {
    display: none;
  }
}
iframe {
  border: none;
  display: block;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}

p {
  strong {
    color: var(--neutral-800);
  }
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 135%;
  strong {
    color: var(--neutral-800);
    font-weight: 400;
  }
}
h1,
.h1 > * {
  font-size: clamp(calc(24rem / 16), calc(26vw / 7.68), calc(36rem / 16));
}
:not(.h3) > h2:not(.h3),
.h2 > * {
  font-size: clamp(calc(12rem / 16), calc(14vw / 7.68), calc(14rem / 16));
  text-transform: uppercase;
  font-family: var(--font-cinzel);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.05em;
  + * {
    margin-top: clamp(8px, calc(12vw / 7.68), 12px);
  }
}
h3,
.h3 > * {
  letter-spacing: -0.02em;
  font-size: clamp(calc(26rem / 16), calc(32vw / 7.68), calc(32rem / 16));
}

.link {
  text-decoration: underline;
  &:hover,
  &:focus-visible {
    opacity: 0.62;
  }
}

.sec-wo-margin {
  position: relative;
  &::before {
    content: '';
    width: 100vw;
    height: 100%;
    background-color: inherit;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
  }
}

.cta-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

main {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: clamp(64px, calc(80vw / 7.68), 128px);
  margin: 40px auto clamp(64px, calc(80vw / 7.68), 128px);
  @media (max-width: 599px) {
    margin-top: 64px;
  }
}

.unorderedList {
  margin-left: 1rem;
}
