.HeroApplication {
  display: grid;
  gap: clamp(48px, calc(64vw / 7.68), 64px) clamp(82px, calc(96vw / 7.68), 134px);
  align-items: flex-start;
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1.2fr;
    header {
      margin-top: 48px;
      position: sticky;
      top: 152px;
    }
  }
  header {
    max-width: calc(484rem / 16);
    h1 {
      margin-bottom: clamp(16px, calc(20vw / 7.68), 20px);
    }
    .paragraph {
      font-size: 1rem;
      > *:not(:last-child) {
        margin-bottom: 12px;
      }
      margin-bottom: clamp(16px, calc(24vw / 7.68), 32px);
    }
    .testimonial {
      padding: clamp(16px, calc(20vw / 7.68), 20px) clamp(16px, calc(24vw / 7.68), 24px);
      border-top: 1px dashed var(--neutral-300);
      .rating {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;
        > div {
          display: flex;
          align-items: center;
          gap: 4px;
        }
        a {
          color: #2e445a;
        }
      }
      .person {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
        .name {
          color: var(--neutral-700);
          font-size: 1rem;
        }
        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          object-position: 50% 25%;
          clip-path: polygon(50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
        }
      }
    }
  }
  .application {
    max-width: calc(608rem / 16);
    border-radius: 16px;
    background: var(--primary--300, #edf0f2);
    color: var(--neutral-700, #333436);
    padding: clamp(16px, calc(24vw / 7.68), 24px);
    @media (max-width: 499px) {
      margin: 0 calc(var(--pageMargin) * -1);
      border-radius: 0;
    }
    .introduction {
      margin-bottom: 32px;
      h2 {
        font-size: clamp(calc(20rem / 16), calc(24vw / 7.68), calc(24rem / 16));
        margin-bottom: clamp(16px, calc(20vw / 7.68), 20px);
      }
      ul {
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: calc(405rem / 16);
        gap: 12px;
        li {
          display: grid;
          gap: 6px;
          grid-template-columns: auto 1fr;
          svg {
            margin-top: 3px;
          }
        }
      }
    }
  }
}
