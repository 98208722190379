.Select {
  font-size: calc(14rem / 16);
  color: var(--primary-800);
  > p {
    &:empty {
      display: none;
    }
    margin: 0 6px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px 4px;
    flex-wrap: wrap;
    svg {
      flex-shrink: 0;
    }
  }
  .select {
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid var(--neutral-500);
    padding: 8px 12px 8px 8px;
    height: 44px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    align-items: center;
    transition: border-color 0.3s var(--easing);
    > p {
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .DropdownIcon {
      transition: transform 0.3s var(--easing);
    }
    .options {
      z-index: 4;
      max-height: 50vh;
      overflow: auto;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      left: -1px;
      right: -1px;
      min-width: 230px;
      top: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: var(--neutral-100);
      border: 1px solid var(--neutral-500);
      box-shadow: 0px 11px 11px 0px rgba(127, 168, 210, 0.09), 0px 25px 15px 0px rgba(127, 168, 210, 0.05);
      label {
        display: block;
        padding: 8px 12px;
        cursor: pointer;
        &:hover,
        &:has(input:checked) {
          background-color: var(--neutral-200);
        }
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: 8px;
        input {
          opacity: 0;
          position: absolute;
        }
        &:not(:has(input:checked)) {
          svg {
            display: none;
          }
        }
      }
    }
    &:focus,
    &:focus-within {
      pointer-events: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .options {
        opacity: 1;
        pointer-events: auto;
      }
      .DropdownIcon {
        transform: rotateX(180deg);
      }
    }
  }
  &[aria-invalid='true'] {
    .select {
      border-color: var(--error-500);
    }
  }
}
