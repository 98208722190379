.Footer {
  display: grid;
  place-items: center;
  padding: 32px 0;
  @media (max-width: 499px) {
    padding-bottom: 48px;
  }
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px 28px;
    svg {
      width: auto;
      height: clamp(24px, calc(28vw / 7.68), 28px);
    }
  }
}
